type Action<T extends string, P = unknown> = {
  type: T;
  payload?: P;
};
type ReducerMap<S, A extends Action<string>> = {
  [K in A["type"]]: (
    state: S,
    action: Pick<Extract<A, { type: K }>, "payload">
  ) => S;
};

export type Dispatch<A> = (action: A) => void;

const getReducer = <T>(reducerKey: keyof T, reducerList: T) =>
  reducerList[reducerKey];

export const createReducer =
  <S, A extends Action<string>>(actions: ReducerMap<S, A>) =>
  (state: S, action: A) =>
    getReducer(action.type, actions)(state, action);
