import { LoadingSpinner } from "src/atoms/LoadingSpinner";
import { Flex } from "@otrium/core";

const ScreenLoader = ({ color = "white" }: { color?: string }): JSX.Element => (
  <Flex
    justifyContent="center"
    alignItems="center"
    sx={{
      height: "100vh",
    }}
  >
    <Flex justifyContent="center" alignItems="center">
      <LoadingSpinner stroke={color} />
    </Flex>
  </Flex>
);

export default ScreenLoader;
