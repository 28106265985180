import { FC, SVGAttributes } from "react";

export const SocialFbIcon: FC<SVGAttributes<SVGSVGElement>> = ({
  width,
  height,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 32}
    height={height || 32}
    viewBox="0 0 32 32"
  >
    <path
      fill="#1878F3"
      fillRule="evenodd"
      d="M16 4C9.373 4 4 9.405 4 16.073 4 22.1 8.388 27.094 14.125 28v-8.437h-3.047v-3.49h3.047v-2.66c0-3.025 1.792-4.697 4.533-4.697 1.312 0 2.686.236 2.686.236v2.971H19.83c-1.491 0-1.956.93-1.956 1.886v2.264h3.328l-.532 3.49h-2.796V28C23.612 27.094 28 22.1 28 16.073 28 9.405 22.627 4 16 4z"
    ></path>
  </svg>
);

export default SocialFbIcon;
