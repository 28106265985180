import { FC, SVGAttributes } from "react";

const GoogleIcon: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.2955 9.00043C22.4289 9.72032 22.5 10.4695 22.5 11.2505C22.5 14.4599 21.3752 17.1843 19.4132 19.0914L19.1526 19.3355H15.5258V16.5745C16.6295 15.8505 17.3901 14.7944 17.7018 13.5463L17.7658 13.255H11.7245V9.00043H22.2955Z"
      fill="#318AF5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.44446 13.09C6.32746 15.6897 8.80183 17.6245 11.7252 17.6245C13.1096 17.6245 14.3055 17.2969 15.2694 16.7339L15.5265 16.5746L19.1533 19.3356C17.2963 21.0149 14.7558 22 11.7252 22C7.46671 22 3.76512 19.6777 1.86224 16.2545L1.69424 15.9402V13.09H5.44446Z"
      fill="#3DA751"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.44376 6.05975V8.90998C5.21927 9.56998 5.09206 10.2752 5.09206 11C5.09206 11.604 5.1804 12.1944 5.33976 12.7563L5.44376 13.09L1.69354 15.9402C0.934014 14.4552 0.5 12.7746 0.5 11C0.5 9.3732 0.864692 7.8255 1.5105 6.43502L1.69354 6.05975H5.44376Z"
      fill="#FDB900"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7252 0C14.6067 0 17.0482 0.988864 18.9538 2.61607L19.2356 2.86489L16.0154 6.01944C14.8531 4.93044 13.3727 4.37556 11.7252 4.37556C8.89928 4.37556 6.49291 6.1835 5.53802 8.6523L5.44446 8.91L1.69424 6.05978C3.54129 2.46522 7.33766 0 11.7252 0Z"
      fill="#EA3E36"
    />
  </svg>
);

export { GoogleIcon };
