import { FC, SVGAttributes } from "react";

export const SocialAppleIcon: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill="#000"
      fillRule="nonzero"
      d="M21.769 15.3a4.947 4.947 0 012.356-4.151 5.065 5.065 0 00-3.99-2.158c-1.68-.176-3.308 1.005-4.164 1.005-.872 0-2.19-.988-3.608-.958a5.315 5.315 0 00-4.473 2.727c-1.934 3.349-.491 8.27 1.361 10.977.927 1.325 2.01 2.805 3.428 2.753 1.387-.058 1.905-.885 3.58-.885 1.658 0 2.144.885 3.59.852 1.489-.025 2.426-1.332 3.32-2.67a10.938 10.938 0 001.52-3.092 4.782 4.782 0 01-2.92-4.4zm-2.732-8.09a4.866 4.866 0 001.115-3.49 4.958 4.958 0 00-3.208 1.66A4.64 4.64 0 0015.8 8.741a4.095 4.095 0 003.237-1.53z"
    ></path>
  </svg>
);

export default SocialAppleIcon;
