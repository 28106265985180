import { FC, useCallback, useState } from "react";
import { Box } from "@otrium/core";
import { Eye } from "src/icons/Eye";
import { EyeSlash } from "src/icons/EyeSlash";
import { useTheme, Theme } from "@emotion/react";
import { FormInput, FormInputProps } from "@otrium/atoms";

interface Props {
  isError?: boolean;
}

const PasswordInput: FC<Props & FormInputProps> = ({
  isError = false,
  ...inputProps
}) => {
  const [isPasswordHidden, setIsPasswordHidden] = useState<boolean>(true);
  const theme: Theme = useTheme();

  const onClick = useCallback(() => {
    setIsPasswordHidden(!isPasswordHidden);
  }, [isPasswordHidden]);

  return (
    <Box
      mb={3}
      sx={{
        position: "relative",
      }}
    >
      <Box
        sx={{
          top: "12px",
          right: "10px",
          position: "absolute",
          zIndex: 2,
        }}
      >
        {isPasswordHidden ? (
          <Eye
            width="24px"
            height="24px"
            style={{ cursor: "pointer" }}
            onClick={onClick}
          />
        ) : (
          <EyeSlash
            width="24px"
            height="24px"
            style={{ cursor: "pointer" }}
            onClick={onClick}
          />
        )}
      </Box>
      {/* @ts-expect-error Fix types in the DS */}
      <FormInput
        {...inputProps}
        type={isPasswordHidden ? "password" : "text"}
        pr="38px"
        pl={3}
        sx={{
          width: "100%",
          color: theme.colors.tone.black,
          border: `${
            isError
              ? `1px solid ${theme.colors.semantic.red}`
              : `1px solid #cccccc;`
          }`,
          borderRadius: "4px",
          height: "48px",
          "::placeholder": {
            color: theme.colors.tone.anthraciteLight,
          },
        }}
      />
    </Box>
  );
};

export default PasswordInput;
