import styled from "@emotion/styled";
import { Flex } from "@otrium/core";
import { Text } from "@otrium/atoms";
import { HyperLink } from "src/atoms/HyperLink";

const ModalWrapper = styled(Flex)`
  width: 532px;
  max-width: 100%;
  padding: ${({ theme }) =>
    `${theme.space.space48} ${theme.space.space16} ${theme.space.space32}`};
  align-items: center;
  flex-direction: column;
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding: ${({ theme }) => theme.space.space32};
  }
  .ProductOrder__order button {
    max-width: 100%;
  }
`;

const SocialButtonsContainer = styled(Flex)`
  width: 100%;
  justify-content: space-between;
`;

const DividerText = styled(Text)`
  position: absolute;
  left: 0;
  right: 0;
  top: -12px;
  width: 30px;
  text-align: center;
  margin: 0 auto;
  z-index: 1;
  line-height: 24px;
  font-size: ${({ theme }) => theme.fontSizes.fontSize16};
  color: ${({ theme }) => theme.colors.tone.black};
  background-color: ${({ theme }) => theme.colors.tone.white};
`;

const InlineHyperLink = styled(HyperLink)`
  display: inline;
  text-decoration: underline;
  &:hover {
    text-decoration-thickness: unset;
    text-decoration: underline;
    text-underline-position: unset;
  }
`;

export { ModalWrapper, SocialButtonsContainer, DividerText, InlineHyperLink };
