import { FC, SVGAttributes } from "react";

export const SocialEmailIcon: FC<SVGAttributes<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M6.01 8L6 24h20V8H6.01zM8 10h16l-8 5-8-5zm16 2.001V22H8v-9.999l8 5 8-5z"
    ></path>
  </svg>
);

export default SocialEmailIcon;
