import { FC, ReactNode } from "react";
import { Text } from "@otrium/atoms";
import { Box } from "@otrium/core";
import SocialFbIcon from "src/icons/SocialFbIcon";
import { GoogleIcon } from "src/icons/GoogleIcon";
import SocialAppleIcon from "src/icons/SocialAppleIcon";
import SocialEmailIcon from "src/icons/SocialEmailIcon";
import { Button } from "src/atoms/Button";

type SocialBtnType = "facebook" | "google" | "apple" | "email";

interface Props {
  type: SocialBtnType;
  fullWidth?: boolean;
  width?: string;
  "data-testid"?: string;
  onClick: () => void;
  children?: ReactNode;
}

const SocialButton: FC<Props> = ({
  type,
  onClick,
  children,
  fullWidth,
  width,
  "data-testid": dataTestId,
  ...rest
}) => {
  let icon;
  switch (type) {
    case "facebook":
      icon = <SocialFbIcon width={32} />;
      break;

    case "google":
      icon = <GoogleIcon width={32} />;
      break;

    case "apple":
      icon = <SocialAppleIcon width={32} />;
      break;

    case "email":
      icon = <SocialEmailIcon width={32} />;
      break;

    default:
      break;
  }

  return (
    <Box mt={3} width={fullWidth ? "100%" : width}>
      <Button
        variant="tertiary"
        onClick={onClick}
        data-testid={dataTestId}
        fullWidth={fullWidth}
        leftIcon={icon}
        {...rest}
      >
        {children && (
          <Text
            width="80px"
            textAlign="left"
            fontSize={1}
            fontWeight="600"
            as="span"
          >
            {children}
          </Text>
        )}
      </Button>
    </Box>
  );
};

export default SocialButton;
