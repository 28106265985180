import { FC, useContext } from "react";
import { LoginForm } from "src/molecules/LoginForm";
import { Trans } from "@lingui/macro";
import { SocialButton } from "src/atoms/SocialButton";
import {
  DividerText,
  InlineHyperLink,
  ModalWrapper,
  SocialButtonsContainer,
} from "./AuthModal.styled";
import { Modal } from "src/atoms/Modal";
import { Text, Heading } from "@otrium/atoms";
import { Box } from "@otrium/core";
import Link from "next/link";
import { signIn } from "src/utils/nextAuth";
import { AuthProvider } from "pages/api/auth/getUserTokenFromApi";
import { Divider } from "src/atoms/Divider";
import { useTheme, Theme } from "@emotion/react";
import { googleTagManager } from "src/tracking";
import { RegisterForm } from "src/molecules/RegisterForm";
import { getI18nRoute } from "src/utils/i18nRoutes";
import { AppCtx } from "src/contexts/app.context";
import { CloseButton } from "src/atoms/CloseButton";

export enum AuthType {
  SIGN_IN = "signIn",
  SIGN_UP = "signUp",
}

interface LoginModalProps {
  open?: boolean;
  authType: AuthType;
  onAuthTypeChange: (authType: AuthType) => void;
  onClose: () => void;
  origin?: string;
}

const AuthModal: FC<LoginModalProps> = ({
  open = false,
  authType,
  onAuthTypeChange,
  onClose,
  origin,
}) => {
  const { locale } = useContext(AppCtx);
  const theme: Theme = useTheme();

  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalWrapper>
        <CloseButton
          fill={theme.colors.tone.black}
          onClick={onClose}
          sx={{ position: "absolute", right: "26px", top: "26px" }}
        />
        <Heading
          my={2}
          as="h3"
          variant="h3"
          fontSize="24px"
          fontFamily="CharisSILW"
          letterSpacing="normal"
          lineHeight="32px"
          pb={0}
          sx={{
            textTransform: "none",
          }}
        >
          {authType === AuthType.SIGN_IN ? (
            <Trans>Log in to Otrium</Trans>
          ) : (
            <Trans>Welcome to Otrium</Trans>
          )}
        </Heading>
        <Text
          variant="body.source.regular16"
          color="tone.anthracite"
          as="p"
          textAlign="center"
        >
          {authType === AuthType.SIGN_UP ? (
            <Trans>Welcome back, choose your way to continue.</Trans>
          ) : (
            <Trans>
              Get free access to hundreds of designer outlet stores.
            </Trans>
          )}
        </Text>
        <SocialButtonsContainer>
          <Box width="100%" mr={2}>
            <SocialButton
              data-testid="login-modal-google-button"
              type="google"
              fullWidth
              onClick={() =>
                void signIn(AuthProvider.GOOGLE, {
                  assumedAuthType:
                    authType === AuthType.SIGN_IN ? "sign_in" : "sign_up",
                  origin,
                })
              }
            >
              Google
            </SocialButton>
          </Box>
          <Box width="100%">
            <SocialButton
              data-testid="login-modal-fb-button"
              type="facebook"
              fullWidth
              onClick={() =>
                void signIn(AuthProvider.FACEBOOK, {
                  assumedAuthType:
                    authType === AuthType.SIGN_IN ? "sign_in" : "sign_up",
                  origin,
                })
              }
            >
              Facebook
            </SocialButton>
          </Box>
        </SocialButtonsContainer>
        <Box
          mt={4}
          mb={2}
          width="100%"
          sx={{
            position: "relative",
          }}
        >
          <Divider color={theme.colors.primary.platinumLight} />
          <DividerText as="p">
            <Trans>or</Trans>
          </DividerText>
        </Box>
        {authType === AuthType.SIGN_IN ? (
          <LoginForm origin={origin} fullWidth />
        ) : (
          <RegisterForm origin={origin} />
        )}
        {authType === AuthType.SIGN_IN ? (
          <Box mt={2}>
            <Link
              data-testid="login-page-forgot-password-link"
              href="/reset-password/"
              passHref
              legacyBehavior
            >
              <Text
                as="a"
                ml={2}
                variant="body.source.semibold16"
                color="Black"
                letterSpacing="0.4px"
                sx={{
                  textDecoration: "underline",
                  ":hover": { cursor: "pointer" },
                }}
              >
                <Trans>Forgot password</Trans>
              </Text>
            </Link>
          </Box>
        ) : (
          <Trans>
            <Text
              as="p"
              mt={2}
              fontSize={0}
              color="tone.anthraciteLight"
              letterSpacing="normal"
              lineHeight="1.43"
              textAlign="center"
            >
              By signing up, you agree with our{" "}
              <InlineHyperLink
                asLink
                variant="body.small"
                color="inherit"
                href={getI18nRoute({ route: "/terms-and-conditions", locale })}
              >
                Terms & Conditions
              </InlineHyperLink>
            </Text>
          </Trans>
        )}
        <Box mt={5}>
          {authType === AuthType.SIGN_IN ? (
            <>
              <Text
                variant="body.source.regular16"
                letterSpacing="0.4px"
                as="p"
                sx={{ display: "inline" }}
              >
                <Trans>New to Otrium?</Trans>
              </Text>
              <InlineHyperLink
                onPress={() => onAuthTypeChange(AuthType.SIGN_UP)}
              >
                <Text as="span" pl={1} variant="body.source.semibold16">
                  <Trans>Register</Trans>
                </Text>
              </InlineHyperLink>
            </>
          ) : (
            <>
              <Text
                variant="body.source.regular16"
                letterSpacing="0.4px"
                as="p"
                sx={{ display: "inline" }}
              >
                <Trans>Already registered? </Trans>
              </Text>
              <InlineHyperLink
                onPress={() => onAuthTypeChange(AuthType.SIGN_IN)}
              >
                <Text
                  as="span"
                  pl={1}
                  variant="body.source.semibold16"
                  onClick={() => googleTagManager.trackAlreadyRegistered()}
                >
                  <Trans>Log in</Trans>
                </Text>
              </InlineHyperLink>
            </>
          )}
        </Box>
      </ModalWrapper>
    </Modal>
  );
};

export default AuthModal;
