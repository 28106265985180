import { useContext } from "react";
import {
  PromotionContext,
  PromotionContextState,
  PromotionContextActions,
} from "src/contexts/promotion.context";
import { Dispatch } from "src/utils/create-reducer";

export function usePromotionContext(): {
  promotionContextState: PromotionContextState;
  promotionContextDispatch: Dispatch<PromotionContextActions>;
} {
  const promotionContext = useContext(PromotionContext);

  if (!promotionContext) {
    throw new Error(
      "Please make sure you are usePromotionContext inside PromotionContextProvider."
    );
  }

  return promotionContext;
}
