import { useState } from "react";
import { t } from "@lingui/macro";
import { Text } from "@otrium/atoms";
import { Flex } from "@otrium/core";
import { useRouter } from "next/router";
import { FormikProps, useFormik } from "formik";
import { signIn } from "src/utils/nextAuth";
import prepareNextAuthErrorMessage from "src/utils/prepareNextAuthErrorMessage";
import { isValidEmail } from "src/utils/validations";
import { AuthProvider } from "pages/api/auth/getUserTokenFromApi";
import Input from "src/atoms/Input";
import { Button } from "src/atoms/Button";
import { PasswordInput } from "src/atoms/PasswordInput";
import FORM_ERROR_MESSAGES from "src/messages/form";
import { Form } from "./LoginForm.styled";
import { useLingui } from "@lingui/react";

interface FormikValues {
  email: string;
  password: string;
  grant_type: string;
}

interface FormikErrors {
  email?: string;
  password?: string;
}

interface LoginFormProps {
  fullWidth?: boolean;
  origin?: string;
}

const LoginForm = ({
  fullWidth = false,
  origin,
}: LoginFormProps): JSX.Element => {
  const [error, setError] = useState<string | undefined>(undefined);

  const router = useRouter();
  const { _, i18n } = useLingui();

  const formik: FormikProps<FormikValues> = useFormik<FormikValues>({
    initialValues: {
      email: "",
      password: "",
      grant_type: "personal",
    },
    validate: (values) => {
      const errors: FormikErrors = {};
      if (!values.email) {
        errors.email = _(FORM_ERROR_MESSAGES.required);
      } else if (!isValidEmail(values.email)) {
        errors.email = _(FORM_ERROR_MESSAGES.invalidEmail);
      }
      if (!values.password) {
        errors.password = _(FORM_ERROR_MESSAGES.required);
      }
      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setError(undefined);

      const response = await signIn(AuthProvider.CREDENTIALS, {
        assumedAuthType: "sign_in",
        origin,
        ...values,
        redirect: false,
      });

      if (response?.url) {
        // NOTE: Assumes that 'response.url' is the same as current URL with '?signin=true'
        void router.push(response.url);
      } else if (response?.error) {
        setError(prepareNextAuthErrorMessage(response.error));
      }
    },
  });

  return (
    <Form fullWidth={fullWidth} noValidate onSubmit={formik.handleSubmit}>
      <Input
        inputTestId="login-form-email-input"
        my={3}
        type="text"
        placeholder={t(i18n)`Email address`}
        {...formik.getFieldProps("email")}
      />
      <PasswordInput
        data-testid="login-form-password-input"
        placeholder={t(i18n)`Password`}
        {...formik.getFieldProps("password")}
      />
      {error && (
        <Text
          my={2}
          color="semantic.red"
          data-testid="login-form-error-message"
        >
          {error}
        </Text>
      )}
      <Flex alignItems="center" justifyContent="center">
        <Button
          data-testid="login-form-login-button"
          fullWidth
          type="submit"
          variant="primary"
          disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
        >
          {t(i18n)`Log in`}
        </Button>
      </Flex>
    </Form>
  );
};

export default LoginForm;
