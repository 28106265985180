import styled from "@emotion/styled";
import { Flex } from "@otrium/core";
import { Button } from "src/atoms/Button";
import { LockUnlockIcon } from "@otrium/icons";

const Wrapper = styled(Flex)`
  background: url("/static/images/sign-up-module-small.jpg")
    ${({ theme }) => theme.colors.tone.black} center;
  background-size: cover;
  flex-direction: column;
  padding: ${({ theme }) => theme.space.space24};
  align-items: unset;
  border-radius: ${({ theme }) => theme.space.space4};
  margin: 0 ${({ theme }) => theme.space.space24};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding: ${({ theme }) => theme.space.space32};
    background-image: url("/static/images/sign-up-module-medium.jpg");
    margin: 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    background-image: url("/static/images/sign-up-module.jpg");
  }
`;

const ButtonsWrapper = styled(Flex)`
  flex-flow: row;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: unset;
  min-height: ${({ theme }) => theme.space.space48};
  height: auto;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: auto;
  }
  &:first-of-type {
    margin-right: ${({ theme }) => theme.space.space16};
  }

  span {
    height: auto;
  }
`;

const SubHeading = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.fontSize16};
  color: ${({ theme }) => theme.colors.tone.white};
  display: flex;
  align-items: center;
  margin: ${({ theme }) => theme.space.space8} 0;

  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    margin-bottom: ${({ theme }) => theme.space.space16};
  }
`;

const UnlockIcon = styled(LockUnlockIcon)`
  flex: 0 0 auto;
  width: 32px;
  height: 32px;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 24px;
    height: 24px;
  }
`;

export { Wrapper, SubHeading, ButtonsWrapper, StyledButton, UnlockIcon };
