import { FC, SVGAttributes } from "react";

const EyeSlash: FC<SVGAttributes<SVGSVGElement>> = ({
  width = "16px",
  height = "16px",
  ...restProps
}) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    width={width}
    {...restProps}
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g fill="#999">
            <path
              d="M10 0c2.241 0 4.27.642 6.085 1.925C17.901 3.208 19.205 4.9 20 7c-.794 2.1-2.1 3.792-3.915 5.075C14.27 13.358 12.241 14 10 14s-4.27-.642-6.085-1.925C2.099 10.792.795 9.1 0 7c.794-2.1 2.1-3.792 3.915-5.075C5.73.642 7.759 0 10 0zm0 2c-1.829 0-3.453.514-4.93 1.558C3.79 4.463 2.83 5.601 2.17 7c.66 1.399 1.62 2.537 2.9 3.442C6.546 11.486 8.17 12 10 12c1.829 0 3.453-.514 4.93-1.558 1.28-.905 2.24-2.043 2.9-3.442-.66-1.399-1.62-2.537-2.9-3.442C13.454 2.514 11.83 2 10 2zm0 1c1.083 0 2.02.396 2.813 1.188C13.604 4.979 14 5.917 14 7c0 1.083-.396 2.02-1.188 2.813C12.021 10.604 11.084 11 10 11c-1.083 0-2.02-.396-2.813-1.188C6.396 9.021 6 8.084 6 7c0-1.083.396-2.02 1.188-2.813C7.979 3.397 8.916 3 10 3zm0 2c-.548 0-.978.182-1.398.602C8.182 6.022 8 6.452 8 7c0 .548.182.978.602 1.398.42.42.85.602 1.398.602.548 0 .978-.182 1.398-.602.42-.42.602-.85.602-1.398 0-.548-.182-.978-.602-1.398C10.978 5.182 10.548 5 10 5z"
              transform="translate(-605 -4861) translate(605 4861) translate(2 5)"
            />
          </g>
          <path
            fillRule="nonzero"
            stroke="#999"
            strokeWidth="2"
            d="M5 5L19 19"
            transform="translate(-605 -4861) translate(605 4861) matrix(-1 0 0 1 24 0)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export { EyeSlash };
