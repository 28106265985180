interface CountryCodeRegexMap {
  [countryCode: string]: RegExp;
}

const countryPostcodeRegex: CountryCodeRegexMap = {
  NL: /^\s*[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}\s*$/i,
  DE: /^\s*(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})\s*$/,
  FR: /^\s*(\d{5}|2[abAB])\s*$/i,
  GB: /^\s*[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9][A-Z]{2}\s*/i,
  ES: /^\s*\d{5}\s*$/,
  IT: /^\s*\d{5}\s*$/,
  HR: /^\s*\d{5}\s*$/,
  PL: /^\s*\d{2}[- ]{0,1}\d{3}\s*$/,
  SE: /^\s*\d{3}\s\d{2}\s*$/i,
  DK: /^\s*\d{4}\s*$/,
  AT: /^\s*\d{4}\s*$/,
  BE: /^\s*\d{4}\s*$/,
  LU: /^\s*\d{4}\s*$/,
  US: /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/,
  CZ: /^[0-9]{3} [0-9]{2}$|^[0-9]{5}$/,
  PT: /^\d{4}((-)\d{3})$/,
  SK: /(^\d{3} \d{2}$)|^\d{5}$/,
  SI: /^\d{4}$/,
  HU: /^\d{4}$/,
  CA: /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] +?[0-9][A-Z][0-9]$/,
  CH: /^\d{4}$/,
};

const phoneRegex =
  /^(\+31|31|031|0031|\+33|33|033|0033|\+39|39|039|0039|\+43|43|043|0043|\+46|46|046|0046|\+44|44|044|0044|\+351|351|0351|00351|\+352|352|0352|00352|\+420|420|0420|00420|\+421|421|0421|00421|\+386|386|0386|00386|\+36|36|036|0036|\+34|34|034|0034|\+1|1|01|001|\+40|40|040|0040|\+385|385|0385|00385|\+359|359|0359|00359|\+30|30|030|0030|\+353|353|0353|00353|\+45|45|045|0045|\+48|48|048|0048|\+49|49|049|0049|\+32|32|032|0032)?([0-9 ]{8,})$/;

export const isValidEmail = (email: string): boolean =>
  /\S+@\S+\.\S+/.test(email);

export const isValidPostcode = (
  postcode: string,
  countryCode: string
): boolean => {
  if (!countryPostcodeRegex[countryCode]) return true;
  return countryPostcodeRegex[countryCode].test(postcode);
};

const streetRegex = /([0-9a-zA-Z])+([-_\s])*$/i;

export const isValidStreet = (street: string): boolean => {
  return streetRegex.test(street);
};

export const isValidPhone = (phone: string): boolean => {
  if (!phone) return false;
  return phoneRegex.test(phone);
};
