import { useState, useEffect, JSX } from "react";
import { Text } from "@otrium/atoms";
import { Box } from "@otrium/core";
import { useSession, RedirectableProvider } from "next-auth/client";
import { useRouter } from "next/router";
import { useFormik, FormikProps } from "formik";
import { t } from "@lingui/macro";
import { signIn } from "src/utils/nextAuth";
import prepareNextAuthErrorMessage from "src/utils/prepareNextAuthErrorMessage";
import { AuthProvider } from "pages/api/auth/getUserTokenFromApi";
import Input from "src/atoms/Input";
import { Button } from "src/atoms/Button";
import { PasswordInput } from "src/atoms/PasswordInput";
import * as Yup from "yup";
import FORM_ERROR_MESSAGES from "src/messages/form";
import { useLingui } from "@lingui/react";

interface FormikValues {
  name: string;
  password: string;
  email: string;
}

const RegisterForm = ({ origin }: { origin?: string }): JSX.Element => {
  const [error, setError] = useState<string | undefined>(undefined);

  const [session] = useSession();
  const router = useRouter();
  const { _, i18n } = useLingui();

  const formik: FormikProps<FormikValues> = useFormik<FormikValues>({
    initialValues: {
      name: "",
      password: "",
      email: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .test(
          "no-only-spaces-check",
          _(FORM_ERROR_MESSAGES.nameRequired),
          (name) => !!name && name.trim().length > 0
        )
        .required(_(FORM_ERROR_MESSAGES.nameRequired)),
      email: Yup.string()
        .required(_(FORM_ERROR_MESSAGES.emailRequired))
        .email(_(FORM_ERROR_MESSAGES.email)),
      password: Yup.string()
        .required(_(FORM_ERROR_MESSAGES.passwordRequired))
        .min(6, _(FORM_ERROR_MESSAGES.passwordLength))
        .matches(/^\S+$/, _(FORM_ERROR_MESSAGES.passwordSpace)),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setError(undefined);

      const response = await signIn(
        AuthProvider.CREDENTIALS_REGISTER as RedirectableProvider,
        {
          assumedAuthType: "sign_up",
          origin,
          ...values,
          redirect: false,
        }
      );

      if (response?.url) {
        // NOTE: Assumes that 'response.url' is the same as current URL with '?signin=true'
        void router.push(response.url);
      } else if (response?.error) {
        setError(prepareNextAuthErrorMessage(response.error));
      }
    },
  });

  useEffect(() => {
    if (
      session &&
      session.user.signInPlatform === AuthProvider.CREDENTIALS_SOFT_LOGIN
    ) {
      void formik.setFieldValue("email", session.user.email);
    }
    // NOTE: Run only once when 'session' becomes available
  }, [session]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box width="100%" px={[5, 0, 0]}>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Input
          {...formik.getFieldProps("name")}
          my={3}
          type="text"
          placeholder={t(i18n)`Name`}
          name="name"
          onChange={formik.handleChange}
          value={formik.values.name}
          onBlur={formik.handleBlur}
          isError={formik.touched.name && formik.errors.name !== undefined}
          aria-invalid={formik.touched.name && formik.errors.name !== undefined}
          aria-describedby="name-error-alert"
        />
        {formik.errors.name && formik.touched.name && (
          <Text
            as="p"
            mt={-2}
            color="semantic.red"
            textAlign="left"
            fontSize={0}
            lineHeight="1.43"
            letterSpacing="normal"
          >
            <span role="alert" id="name-error-alert">
              {formik.errors.name}
            </span>
          </Text>
        )}
        <Input
          {...formik.getFieldProps("email")}
          mb={3}
          type="text"
          placeholder={t(i18n)`Email`}
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          isError={formik.touched.email && formik.errors.email !== undefined}
          aria-invalid={
            formik.touched.email && formik.errors.email !== undefined
          }
          aria-describedby="email-error-alert"
        />

        {formik.errors.email && formik.touched.email && (
          <Text
            as="p"
            mt={-2}
            color="semantic.red"
            textAlign="left"
            fontSize={0}
            lineHeight="1.43"
            letterSpacing="normal"
          >
            <span role="alert" id="email-error-alert">
              {formik.errors.email}
            </span>
          </Text>
        )}
        <PasswordInput
          {...formik.getFieldProps("password")}
          placeholder={t(i18n)`Password`}
          name="password"
          onChange={formik.handleChange}
          value={formik.values.password}
          onBlur={formik.handleBlur}
          isError={
            formik.touched.password && formik.errors.password !== undefined
          }
          aria-invalid={
            formik.touched.password && formik.errors.password !== undefined
          }
          aria-describedby="password-error-alert"
        />
        {formik.errors.password && formik.touched.password && (
          <Text
            as="p"
            mt={-2}
            color="semantic.red"
            textAlign="left"
            fontSize={0}
            lineHeight="1.43"
            letterSpacing="normal"
          >
            <span role="alert" id="password-error-alert">
              {formik.errors.password}
            </span>
          </Text>
        )}
        {error && (
          <Text
            my={2}
            color="semantic.red"
            data-testid="register-form-error-message"
          >
            {error}
          </Text>
        )}
        <Button
          disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
          fullWidth
          data-testid="product-order-button"
          className="ProductOrder__order"
          variant="primary"
          type="submit"
        >
          {t(i18n)`Create account`}
        </Button>
      </form>
    </Box>
  );
};

export default RegisterForm;
